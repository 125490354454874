import React from 'react';
import classnames from 'classnames';
import styles from './PageSectionHeader.module.scss';
import { ReactComponent as ArrowLeftIcon } from '../../images/icons/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../../images/icons/arrowRight.svg';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const PageSectionHeader = ({
  children,
  classList,
  withSliderControls,
  sliderNext,
  sliderPrevious,
  link = '',
  seeAllTitle = '',
}) => {
  const defaultRootClassname = withSliderControls ? styles.rootWithSliderControls : styles.root;

  return (
    <header
      className={classnames('container', {
        [classList?.root]: classList?.root,
        [defaultRootClassname]: !classList?.root,
      })}
    >
      <h2 className={classList?.title || styles.title}>{children}</h2>
      {!!withSliderControls && (
        <div className={classList?.sliderControls || styles.sliderControls}>
          <ArrowLeftIcon className={styles.arrow} viewBox="0 0 16 16" onClick={sliderPrevious} />
          <ArrowRightIcon className={styles.arrow} viewBox="0 0 16 16" onClick={sliderNext} />
        </div>
      )}
      {link && (
        <div className={styles.seeAll}>
          <Link to={link}>
            {seeAllTitle ? seeAllTitle : <FormattedMessage id="common.seeAllDeals" defaultMessage="See all deals" />}
          </Link>
          <ArrowRightIcon />
        </div>
      )}
    </header>
  );
};

export default PageSectionHeader;
