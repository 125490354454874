import PropTypes from 'prop-types';
import React from 'react';

import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_ALL_CATEGORIES, ARD_CATEGORY_CONTENT } from '../../utils/appRoutes.definitions';
import { useIntl } from 'react-intl';
import styles from './CategoryPage.module.scss';
import RelatedCategories from '../RelatedCategories';

const CategoryBreadcrumb = ({ categories = [], isMobile = false }) => {
  const intl = useIntl();
  const items = categories.reduce(
    (acc, { name, slug }, idx) => {
      const previousSlug = idx > 0 ? acc[idx].slug : undefined;
      const categoryPath = previousSlug ? previousSlug + '/' + slug : slug;
      const url = categoryPath ? getPagePath(ARD_CATEGORY_CONTENT, categoryPath) : '/';
      return [...acc, { slug, text: name, url, ignoreExistingQueryString: false }];
    },
    [
      {
        slug: getPagePath(ARD_ALL_CATEGORIES),
        text: intl.formatMessage({ id: 'categories.headerAll' }),
        url: getPagePath(ARD_ALL_CATEGORIES),
      },
    ]
  );

  const mobileItem = items.filter((_, index) => index !== items.length - 1);

  if (isMobile) {
    return <RelatedCategories items={mobileItem} />;
  }

  return <Breadcrumb items={items} classList={{ root: styles.breadCrumbs }} />;
};

CategoryBreadcrumb.displayName = 'CategoryBreadcrumb';
CategoryBreadcrumb.propTypes = {
  categories: PropTypes.array,
};

export default CategoryBreadcrumb;
