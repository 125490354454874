import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import constructURL from '../../utils/constructURL';

import { ReactComponent as HomeIcon } from '../../images/icons/home.svg';

import styles from './Breadcrumb.module.scss';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_HOME } from '../../utils/appRoutes.definitions';

const Breadcrumb = ({
  baseUrl,
  intl,
  classList = {},
  skipDefaultHome,
  items = [],
  ignoreExistingQueryString = true,
  activeLastItem,
}) => {
  return (
    <>
      <nav className={classnames(styles.root, 'col-12-xs', classList?.root || '')}>
        <ul>
          {!skipDefaultHome && (
            <li
              className={classnames(styles.homeItem, {
                [styles.mobileHomeItem]: !items.length || (!activeLastItem && items.length === 1),
              })}
            >
              <Link to={getPagePath(ARD_HOME)}>
                <HomeIcon className={styles.homeIcon} />
                <FormattedMessage defaultMessage="Home" id="common.home" />
              </Link>
            </li>
          )}
          {items &&
            items.map((item, idx) => (
              <Fragment key={`breadcrumb_item_${item.slug}`}>
                <li
                  className={classnames(styles.breadcrumbItem, {
                    [styles.liWithArrow]: !(skipDefaultHome && !idx),
                    [styles.mobileLi]: !activeLastItem,
                  })}
                >
                  {idx !== items.length - 1 || activeLastItem ? (
                    <Link
                      to={constructURL(item.url, {
                        ignoreExistingQueryString:
                          item?.ignoreExistingQueryString !== undefined
                            ? item.ignoreExistingQueryString
                            : ignoreExistingQueryString,
                      })}
                    >
                      {item.showHomeIcon && <HomeIcon className={styles.homeIcon} />}
                      {item.text}
                    </Link>
                  ) : (
                    item.text
                  )}
                </li>
              </Fragment>
            ))}
        </ul>
      </nav>
      {items.length > 0 && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: items
                .filter((item) => item.url)
                .map((item, idx) => {
                  return {
                    '@type': 'ListItem',
                    position: idx + 2,
                    name: item.text,
                    item: `${baseUrl}${item.url}`,
                  };
                })
                .reduce((acc, item) => [...acc, item], [
                  {
                    '@type': 'ListItem',
                    position: 1,
                    name: intl.messages['common.home'],
                    item: `${baseUrl}/`,
                  },
                ]),
            }),
          }}
        />
      )}
    </>
  );
};

Breadcrumb.displayName = 'Breadcrumb';
Breadcrumb.propTypes = {
  items: PropTypes.array,
};

const mapStateToProps = ({ config, intl }) => ({ baseUrl: config.baseUrl, intl });
export default connect(mapStateToProps)(Breadcrumb);
